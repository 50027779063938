import React from 'react';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import LocaleSelect from '../../../molecules/LocaleSelect';
import { useTranslation } from '../../../../config/i18n';

import { SubFooterContainer } from './style';

export default function SubFooter() {
  const { t } = useTranslation(['footer']);

  return (
    <SubFooterContainer>
      <Container maxWidth="xl">
        <Grid container justify="space-between" alignItems="baseline">
          <Grid item xs={8} md={6}>
            <Typography variant="body2">
              © {new Date().getFullYear()} {t('copyright')}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2} className="locales">
            <LocaleSelect id="footer-language-menu" />
          </Grid>
        </Grid>
      </Container>
    </SubFooterContainer>
  );
}
