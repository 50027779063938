// export const {LINKEDIN_STATE} = "DCEeFWf45A53sdfKef424";
// const {LINKEDIN_SCOPE} = "r_liteprofile r_emailaddress";
// const {LINKEDIN_REDIRECT} = process.env.NEXT_PUBLIC_REDIRECT_URI || { LINKEDIN_REDIRECT: 'https://www.linkedin.com/oauth/v2/authorization' };
// const {LINKEDIN_CLIENT_ID} = process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID || 'https://www.linkedin.com/oauth/v2/authorization';
// // eslint-disable-next-line no-unused-vars
// const {LINKEDIN_REDIRECT_CONNECT} = process.env;

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${base}?${query}`;
};

export const LINKEDIN_URL = getURLWithQueryParams(
  "https://www.linkedin.com/oauth/v2/authorization",
  {
    response_type: "code",
    // client_id: LINKEDIN_CLIENT_ID,
    // redirect_uri: LINKEDIN_REDIRECT,
    // state: LINKEDIN_STATE,
    // scope: LINKEDIN_SCOPE
  }
);


export const queryToObject = queryString => {
  const pairsString =
    queryString[0] === "?" ? queryString.slice(1) : queryString;
  const pairs = pairsString
    .split("&")
    .map(str => str.split("=").map(decodeURIComponent));
  return pairs.reduce(
    (acc, [key, value]) => (key ? { ...acc, [key]: value } : acc),
    {}
  );
};