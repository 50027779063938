import React from 'react';
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel';

import { redirectToRoute } from '@app/helpers';
import { useUser } from '@app/helpers/hooks';

import OpportunityCard from '../../opportunities/OpportunityCard';
import { CardContainer, SliderContainer } from './style';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
    removeArrowOnDeviceType: true // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const handleViewOpportunity = slug => () => {
  redirectToRoute({}, 'view_opportunity', { params: { slug } });
};

// eslint-disable-next-line react/prop-types
const FeaturedSlider = ({ opportunities, responsivnes, ...props }) => {
  const { user } = useUser();

  return (
    <SliderContainer>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsivnes}
        ssr // means to render carousel on server-side.
        infinite
        //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2000}
        // autoPlay
        keyBoardControl
        //   customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        //   deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        {...props}
      >
        {opportunities.map((opportunity) => (
          // <Grid key={opportunity.id} item xs={12} sm={12} md={10} lg={4}>
          <CardContainer key={opportunity.slug}>
            <OpportunityCard
              userInfo={user}
              {...opportunity}
              onClick={handleViewOpportunity(opportunity.slug)}
            />
          </CardContainer>
        ))}
      </Carousel>
    </SliderContainer>
  );
};

FeaturedSlider.propTypes = {
  opportunities: PropTypes.arrayOf(PropTypes.object).isRequired,
  responsivnes: PropTypes.object,
};

FeaturedSlider.defaultProps = {
  responsivnes: responsive,
};

export default FeaturedSlider;
