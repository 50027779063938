import styled from 'styled-components';
import MuiTextField from '@mui/material/TextField';

export const StyledTextField = styled(MuiTextField)`
  && {
    .MuiInputBase-root {
      border-radius: 25px;
    }
    // MuiInputLabel-root {
    //   margin-left: 10px;
    // }
  }
`;
