import styled from 'styled-components';
import { Grid, Divider } from '@mui/material';
import { deviceSizes } from '../../../../config/devices';

export const StyledGrid = styled(Grid)`
  && {
    background-color: #0f162a;
    text-align: center;
    .featureSlider {
      padding: 5rem;
      @media (max-width: ${deviceSizes.mobileL}) {
        padding: 1rem 0 1rem 0;
      }
    }
  }
`;

export const MetaContainer = styled(Grid)`
  && {
    justify-content: center;
    align-items: center;
    flex-direction: ${({ direction }) => direction};
    text-align: justify;
  }
`;

export const StiledImagBox = styled.div`
  border-radius: 100px;
  height: 423px;
  width: 100%;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 150px;
    height: 150px;
    color: white;
  }
  img {
    border-radius: 100px;
  }
`;

export const ButtonGroupBox = styled.div`
  position: absolute;
  top: 5px;
  right: 70px;
  button {
    width: 40px;
    height: 40px;
    color: white;
    border-radius: 50%;
    padding: 10px;
    box-shadow: none;
    margin: 5px;
    text-align: center;
    background-color: ${({ theme }) => theme.primary.main};
    &:hover {
      background-color: ${({ theme }) => theme.primary.dark};
    }
  }

  @media (max-width: ${deviceSizes.sm}) {
    display: none;
  }
`;

export const StyledSectionDivider = styled(Divider)`
  && {
    height: 2px;
    width: 70%;
    margin: 0 auto;
    background: linear-gradient(
      270deg,
      #231b42 -0.56%,
      #b959a9 4.68%,
      #6854e0 30.87%,
      #2bb1db 63.34%,
      #4f4087 79.57%,
      #aeaf63 95.81%,
      #231b42 100%
    );
  }
`;

export const OrganizerScientis = styled.div`
  display: flex;
  justify-content: center;
  .noActive {
    width: 20%;
    border-bottom: 2px solid #87b1f2;
    padding-bottom: 10px;
  }
  .active {
    width: 20%;
    border-bottom: 8px solid #87b1f2;
    padding-bottom: 10px;
  }
`;
