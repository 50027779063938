/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@mui/material';
import Head from 'next/head';
import { BoxContianer, LandingContainer, StyledDivider } from './style';
import Header from '../../organisms/common/Header';
import Footer from '../../organisms/common/Footer';
// import CookieAlert from '../../molecules/common/CookieAlert';

const HeaderOneColumn = ({ children, secondSection, title, usrState, userObject }) => (
  <>
    <Head>
      <title>{`SciVenia ${title}`}</title>
    </Head>
    {/* <Container> */}
    <Header linkedInUser={userObject} />
    {/* <CookieAlert /> */}
    <LandingContainer>
      {/* <BoxContianer> */}
      <Grid container>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          {children}
        </Grid>
      </Grid>
      {/* </BoxContianer> */}
    </LandingContainer>
    <Grid container id="how_it_works_section" >
      {/* <Grid item> */}
      {secondSection}
      {/* </Grid> */}
    </Grid>
    <Footer />
    {/* </Container> */}
  </>
);

HeaderOneColumn.propTypes = {
  children: PropTypes.node.isRequired,
  secondSection: PropTypes.node.isRequired,
  title: PropTypes.string,
  usrState: PropTypes.object,
  userObject: PropTypes.object

};

HeaderOneColumn.defaultProps = {
  title: '',
  usrState: '',
  userObject: ''
};
export default HeaderOneColumn;
