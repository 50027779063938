import styled from 'styled-components';

export const SubFooterContainer = styled.div`
  background-color: ${({ theme }) => theme.secondaryColor[700]};
  color: ${({ theme }) => theme.white};
  padding: 0 10%;
  .locales {
    border-left: 1px solid ${({ theme }) => theme.secondaryColor[600]};
    border-right: 1px solid ${({ theme }) => theme.secondaryColor[600]};
    padding: 0.5rem;
  }
`;
