
import styled from 'styled-components';
import { deviceSizes } from '../../../../config/devices';

export const CardContainer = styled.div`
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  height: 100%;

  /* max-width: 25rem; */

  //   /* padding-bottom: 2rem; */

  //   @media only screen and (min-width: ${deviceSizes.tablet}) and (max-width: ${deviceSizes.md}) {
  //     padding-right: 12rem;
  //     padding-left: 12rem;
  //   }

  //   @media only screen and (min-width: ${deviceSizes.mobileL}) and (max-width: ${deviceSizes.sm}) {
  //     padding-right: 1rem;
  //     padding-left: 1rem;
  //   }
  @media (max-width: ${deviceSizes.sm}) {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  @media (max-width: ${deviceSizes.mobileL}) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export const SliderContainer = styled.div`
  text-align: start;


  @media (max-width: ${deviceSizes.mobileL}) {
    padding-top: 2rem;
  }
`;

// export const useClasses = makeStyles({
//   CardContainerStyle: {
//     paddingRight: '4.5rem',
//     paddingLeft: '4.5rem',
//     height: '100%',
//     [`@media only screen and (min-width: ${deviceSizes.tablet}) and (max-width: ${deviceSizes.md}) `]: {
//       paddingRight: '12rem',
//       paddingLeft: '12rem',
//     },

//     [`@media only screen and (min-width: ${deviceSizes.mobileL}) and (max-width: ${deviceSizes.sm})`]: {
//       paddingRight: '1rem',
//       paddingLeft: '1rem',
//     },

//     [`@media (max-width: ${deviceSizes.mobileL})`]: {
//       paddingRight: '0',
//       paddingLeft: '0',
//     },
//   },
// });
