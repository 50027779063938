import React, { useState } from 'react';
import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import { Button } from './style';

import { i18n,
  //  useTranslation, config 
  } from '../../../config/i18n';
import { MUBAZAR_LANGUAGES } from '../../../constants';

// const languages = {
//   en: "English",
//   fr: "Française",
//   de: "Deutsche",
//   es: "Español",
//   it: "Italiano",
//   nl: "Nederlands",
//   hr: "Hrvatski",
//   pt: "Português",
//   ar: "عربي",
//   ru: "Русский"
// };

export default function LocaleSelect ({id}) {
  // const { t } = useTranslation(['language']);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = (lang) => () => {
    i18n.changeLanguage(lang);
    handleClose();
  };

	return (
    <>
      <Button size="small" aria-controls={id} aria-haspopup="true" onClick={handleClick}>
        <LanguageIcon fontSize="small" /> 
        <Typography variant="button">
          {typeof(i18n.language) !== 'undefined' ? i18n.language : i18n.options.defaultLanguage}
        </Typography>
      </Button>
      <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.keys(MUBAZAR_LANGUAGES).map(
            (lang) => <MenuItem key={lang} onClick={handleLanguage(lang)}>{ MUBAZAR_LANGUAGES[lang].nativeName }</MenuItem>
          )}
        </Menu>
    </>
  );
}

LocaleSelect.propTypes = {
id: PropTypes.string
}

LocaleSelect.defaultProps = {
id: 'language-menu'
}