/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextField } from './style';

const TextField = ({ variant, label, margin, children, fullWidth, type, multiline, ...props }) => (
    <StyledTextField
      label={label}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      type={type}
      multiline={multiline}
      {...props}
    >
      {children}
    </StyledTextField>
  );

TextField.propTypes = {
  variant: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  multiline: PropTypes.bool,
};

TextField.defaultProps = {
  margin: 'normal',
  fullWidth: true,
  variant: 'outlined',
  label: undefined,
  type: undefined,
  multiline: false,
};

export default TextField;
