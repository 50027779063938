/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Landing from '@templates/Landing';
import { useRouter } from 'next/router';
import axios from 'axios';
import _ from 'lodash';
// import HeaderWithSidebar from '@templates/HeaderWithSidebar';
// import HomeInfo from '@organisms/common/HomeInfo';
import HowItWorks from '@organisms/auth/HowItWorks';

import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@app/apollo/queries';
// import VersionModal from '@organisms/common/VersionModal'
import { goToDashboard, isUser, redirectToRoute, isOrganiser } from '@app/helpers';
import { OK } from '@app/constants/guard-code';
import { useUser } from '../../helpers/hooks/useUser';
import { getURLWithQueryParams } from '../../helpers/linkedInauth';
import LandingPage from '../landing-page';

const Home = () => {
  // const [open, setOpen] = React.useState(true);
  const { data, loading } = useQuery(USER_QUERY);
  const router = useRouter();
  const [user, setUser] = useState({});
  const [userinfo, setUserInfo] = useState({});

  // const requestSever = async () => {
  //   const { code, state } = router.query;

  //   if (code === undefined) return;

  //   // Query to exchange our authorization code for an access token

  //   const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/accessToken', {
  //     grant_type: 'authorization_code',
  //     // eslint-disable-next-line object-shorthand
  //     code: code,
  //     redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI,
  //     client_id: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
  //     client_secret: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_SECRET,
  //   });
  //   let tok;
  //   const resp = await fetch(LINKEDIN_URL, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //   });

  //   if (resp.ok) tok = await resp.json();

  //   const { access_token, expires_in } = tok;

  //   // Query to exchange our token for user infos
  //   let auth = `Bearer ${access_token}`;
  //   let u = {};
  //   const usr = await fetch('https://api.linkedin.com/v2/me', {
  //     method: 'GET',
  //     headers: { Connection: 'Keep-Alive', Authorization: auth },
  //   });
  //   if (usr.ok) u = await usr.json();

  //   if (u.localizedFirstName) {
  //     // if(typeof window === 'undefined'){
  //     //   router.push('/');
  //     // }
  //     setUserInfo(u);
  //     console.log('User Info:', u);

  //     redirectToRoute({}, 'opportunity_search');
  //   }
  //   // const authToken = localStorage.getItem("access_token");

  //   // try {
  //   //   const response = await axios.get(`/api/auth/${code}`);
  //   //   if (response.status === 200) {
  //   //     setUser(response.data);

  //   //     router.push("/");
  //   //   }
  //   // } catch (error) {
  //   // }
  // };

  // useEffect(() => {
  //   requestSever();
  // }, [router.query]);

  if (loading) {
    return <div />;
  }

  if (data && data.me) {
    return redirectToRoute({}, isOrganiser(data.me) ? 'my_opportunities' : 'opportunity_search');
  }

  /* 
  <Landing secondSection={<HowItWorks />} title="" userObject={userinfo}>
        <HomeInfo />
        // <VersionModal open={open} setOpen={setOpen} /> 
      </Landing >
  */

  return (
    <>
      <LandingPage />
      {/* <Landing secondSection={<HowItWorks />} title="" userObject={userinfo}>
        <HomeInfo />
      </Landing> */}

    </>
  );
};

Home.guard = async (ctx, user) => {
  if (isUser(user)) {
    goToDashboard(ctx, user);
  }
  return OK;
};

export async function getServerSideProps() {
  return {
    props: {
      namespacesRequired: [
        'landing',
        'common',
        'dashboard',
        'footer',
        'applications',
        'wizard',
        'explore',
        'payment',
        'howItWorks',
      ],
    },
  };
}

export default Home;
